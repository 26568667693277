"use client"

import Link from "next/link";
import { Button, buttonVariants } from "./ui/button";
import { signOut } from "next-auth/react"
import { cn } from "@/lib/utils";

export default function SignOutButton() {
    return (
        <Button className={cn(buttonVariants({ variant: "secondary" }), "w-full")} onClick={() => signOut()}>Sign out</Button>
    )
}
