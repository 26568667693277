"use client";

import SignInButton from "@/components/SignInButton";
import SignOutButton from "@/components/SignOutButton";
import { AnimatePresence, Variants, motion } from "framer-motion";
import Link from "next/link";
import { ReactNode, useState } from "react";
import { usePathname } from "next/navigation";
import { useSession } from "next-auth/react";

export default function MobileNav({
    children,
}: {
    children: ReactNode;
}) {
    const links: {
        public: { name: string; url: string }[];
        user: { name: string; url: string }[];
    } = {
        public: [
            {
                name: "Home",
                url: "/",
            },
            // {
            //     name: "Shop",
            //     url: "/shop",
            // },
            {
                name: "Birre",
                url: "/birre",
            },
            {
                name: "Associazione",
                url: "/associazione",
            },
            {
                name: "Contatti",
                url: "/contatti",
            },
        ],
        user: [
            {
                name: "Ordini",
                url: "/ordini",
            },
            {
                name: "Preferenze",
                url: "/preferenze",
            },
        ],
    };
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const [beersOpen, setBeersOpen] = useState(false);

    const { data: session } = useSession();

    const pathName = usePathname();

    // const [isAdmin, setIsAdmin] = useState(false)
    // const [, startTransition] = useTransition()
    //
    // useEffect(() => {
    //     startTransition(async () => {
    //         const res = await getUserRole()
    //         setIsAdmin(res)
    //     })
    // }, [isAdmin])

    function closeNav() {
        setMobileNavOpen(false);
        setBeersOpen(false);
    }

    const mobileMenuVariant: Variants = {
        opened: {
            y: "-3rem",
            transition: {
                duration: 0.3,
                // ease: "easeOut",
                type: "spring",
                bounce: 0.3,
            },
        },
        closed: {
            y: "-200%",
            transition: {
                duration: 0.2,
                // ease: "easeOut",
                type: "tween",
            },
        },
    };

    return (
        <>
            <AnimatePresence>
                {mobileNavOpen &&
                    <>
                        <motion.div
                            key="mobileNavBackdrop"
                            className="absolute w-screen h-screen top-0 left-0 bg-[rgba(0,0,0,30%)]"
                            onTouchStart={closeNav}
                            onClick={closeNav}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        />

                        <motion.nav
                            key="mobileNav"
                            variants={mobileMenuVariant}
                            initial="closed"
                            animate="opened"
                            exit="closed"
                            className="fixed z-40 top-0 right-0 bg-popover text-popover-foreground text-end text-2xl sm:text-3xl pb-4  sm:pb-8 pt-16 sm:pt-16  rounded-bl-lg shadow max-h-[100vh] overflow-y-auto lg:hidden"
                        >
                            <motion.button onClick={closeNav} className="px-4 sm:px-8">
                                <span className="font-bold">X</span>
                            </motion.button>
                            <motion.ul className="flex flex-col gap-4 mt-4">
                                {links.public.map((l) => {
                                    if (l.url === "/birre") {
                                        return (
                                            <li key={l.url}>
                                                <span
                                                    // className={`cursor-pointers ${beersOpen ? "bg-background px-4 pt-2 mr-[-1rem] rounded-t-lg" : ""}`}
                                                    className="cursor-pointer px-4 sm:px-8"
                                                    onClick={() => {
                                                        setBeersOpen(!beersOpen);
                                                    }}
                                                >
                                                    Birre
                                                    <span className="ml-1 text-lg">
                                                        {beersOpen ? "▲" : "▼"}
                                                    </span>
                                                </span>
                                                <AnimatePresence>
                                                    {!!beersOpen && (
                                                        <motion.div
                                                            initial={{
                                                                height: 0,
                                                            }}
                                                            animate={{
                                                                height: "auto",
                                                            }}
                                                            exit={{
                                                                height: 0,
                                                            }}
                                                            transition={{
                                                                duration: 0.1,
                                                                // type: "spring",
                                                                // bounce: 0.3,
                                                            }}
                                                            className=""
                                                        >
                                                            {children}
                                                        </motion.div>
                                                    )}
                                                </AnimatePresence>
                                            </li>
                                        );
                                    }
                                    return (
                                        <motion.li whileTap={{ scale: 0.95 }} key={l.url}>
                                            <Link
                                                className={`my-auto ${pathName === l.url && "font-semibold"} px-4 sm:px-8`}
                                                onClick={() => {
                                                    setMobileNavOpen(false);
                                                }}
                                                href={l.url}
                                            >
                                                {l.name}
                                            </Link>
                                        </motion.li>
                                    );
                                })}

                                <hr className="border mx-4 sm:mx-8" />

                                {!!session ? (
                                    <>
                                        {links.user.map((l) => (
                                            <motion.li whileTap={{ scale: 0.95 }} key={l.url} className="px-4 sm:px-8">
                                                <Link
                                                    onClick={() => {
                                                        setMobileNavOpen(false);
                                                    }}
                                                    href={l.url}
                                                >
                                                    {l.name}
                                                </Link>
                                            </motion.li>
                                        ))}
                                        {/* {!!isAdmin && <li><a className="text-destructive" href={"/admin"} target="_blank">Amministrazione</a></li>} */}
                                        <div className="text-xl px-4 sm:px-8">{session.user.email}</div>
                                        <div className="flex items-center justify-end pt-4 gap-8 px-4 sm:px-8">
                                            {/* <ModeToggle /> */}
                                            <SignOutButton />
                                        </div>
                                    </>
                                ) : (
                                    <div className="flex items-center justify-end pt-4 gap-8 px-4 sm:px-8">
                                        {/* <ModeToggle /> */}
                                        <SignInButton />
                                    </div>
                                )}
                            </motion.ul>
                        </motion.nav>
                    </>
                }
            </AnimatePresence>

            <div className="flex items-center gap-5 lg:hidden">
                <button
                    onClick={() => {
                        setMobileNavOpen(true);
                    }}
                    data-collapse-toggle="navbar-default"
                    type="button"
                    className="inline-flex items-center w-6 h-6 sm:w-8 sm:h-8 justify-center text-md  rounded-lg  focus:outline-none "
                    aria-controls="navbar-default"
                    aria-expanded="false"
                >
                    <span className="sr-only">Open main menu</span>
                    <svg
                        className="w-10 h-10"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 17 14"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 1h15M1 7h15M1 13h15"
                        />
                    </svg>
                </button>
            </div>
        </>
    );
}
